<template>
  <div class="g-section">
    <div class="g-wrap wrap-main">
      <div class="wrap-left">
        <el-menu default-active="1" class="el-menu-vertical-demo w100">
          <el-menu-item index="1">
            <i class="el-icon-menu"></i>
            <span slot="title">人才订单</span>
          </el-menu-item>
          <el-menu-item index="3">
            <i class="el-icon-document"></i>
            <span slot="title">企业</span>
          </el-menu-item>
           <el-menu-item index="2">
            <i class="el-icon-menu"></i>
            <span slot="title">资讯</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="wrap-right">
        <div class="info-item" v-for="i in 6" :key="i">
           收藏信息{{i}}
        </div> 
      </div>
    </div>
  </div>
</template>

<script> 
export default {
  data() {
    return {
      
    }
  }, methods: {
  }
}
</script>

<style lang="scss" scoped>
.wrap-left {
  display: flex;
  width: 210px;
}
.wrap-right {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  padding: 10px;
  .info-item{
    display: flex;
    border: 1px solid #dddddd;
    height: 120px;
    margin-bottom: 10px;
  }
}
.wrap-module {
  display: flex;
  flex-direction: column;
  min-height: 120px;
  border-bottom: 1px solid #dddddd;
}
.resume-module-top {
  display: flex;
  justify-content: space-between;
  .resume-title-box {
    display: flex;
    color: #409eff;
    font-size: 20px;
    align-items: center;
  }
  .resume-title {
    margin-left: 5px;
    color: #409eff;
    font-size: 18px;
  }
}
.resume-module-bottom {
  display: flex;
  flex-direction: column;
}
</style>